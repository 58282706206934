import { i18n } from "@/i18n";

export function getRolesLabels(roles: string[]) {
  return roles.map((r) => i18n.t(`userRoles.${r}`) as string);
}

export function getRolesOptions(roles: string[]) {
  return roles.map((r) => ({
    value: r,
    text: i18n.t(`userRoles.${r}`) as string,
  }));
}
