import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { getClientInstance } from "@/services/client/clientProvider";
import { SubCompanyModel } from "@/services/types/subCompanies";
import {
  CompanyEmployeeModel,
  CreateEmployeeRequestModel,
} from "@/services/types/employee";
import { StatusType } from "@/services/types/base";

export function getDefaultFilter() {
  return {
    search: "",
    status: null,
  };
}

export const useCompanyUsersStore = defineStore("subcompanyUsers", () => {
  const state = {
    isDataLoading: ref(false),
    company: ref<SubCompanyModel>(null),
    users: ref<CompanyEmployeeModel[]>([]),
    filter: ref<{ search: string; status: string }>(getDefaultFilter()),
  };

  const getUsers = computed<CompanyEmployeeModel[]>(() => {
    return state.users.value;
  });

  const getFilteredUsers = computed<CompanyEmployeeModel[]>(() => {
    const status = (
      state.filter.value.status ? state.filter.value.status : null
    ) as StatusType;
    const search = state.filter.value.search ? state.filter.value.search : "";
    return filterByStatus(
      status as StatusType,
      filterByName(search, getUsers.value)
    );
  });

  const actions = {
    async fetchUsers(companyId: number) {
      state.isDataLoading.value = true;
      try {
        const client = getClientInstance();
        const resp = await client.getEmployeesList();

        if (companyId) {
          state.company.value = resp.subcompanies.find(
            (c) => c.id === companyId
          );
          state.users.value = state.company.value.users;
        } else {
          state.company.value = null;
          state.users.value = resp.users;
        }
      } finally {
        state.isDataLoading.value = false;
      }
    },

    async deleteUser(userId: number) {
      await getClientInstance().deleteEmployeesList({
        ids: [userId],
      });
    },

    async createUser(model: CreateEmployeeRequestModel) {
      return await getClientInstance().createEmployee(model);
    },
  };

  return {
    ...state,
    ...actions,
    getUsers,
    getFilteredUsers,
  };
});

function filterByStatus(
  status: StatusType,
  list: CompanyEmployeeModel[]
): CompanyEmployeeModel[] {
  if (status === null) {
    return list;
  } else {
    return list.filter((item) => item.status === status);
  }
}

function filterByName(
  search: string,
  list: CompanyEmployeeModel[]
): CompanyEmployeeModel[] {
  if (search.length >= 2) {
    return list.filter((item) => {
      const name = `${item.lastname}${item.firstname}${item.surname}`;
      return (
        name.trim().toLowerCase().indexOf(search.trim().toLowerCase()) >= 0
      );
    });
  } else {
    return list;
  }
}
